<template>
  <el-dialog :close-on-click-modal="false" v-model="dialogFormVisible" title="股权变更信息" width="800px" destroy-on-close>
    <div class="top_select">
      <el-button @click="add" type="primary" size="small" plain icon="Plus">添加</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" border v-loading="loading" height="400">
      <el-table-column type="index" width="50" label="序号" align="center" />
      <el-table-column prop="shareholder" label="股东" width="100px" />
      <el-table-column prop="frontProportion" label="变更前股权比例" width="120px" />
      <el-table-column prop="afterProportion" label="变更后股权比例" width="120px" />
      <el-table-column prop="changeDate" label="股权变更日期" />
      <el-table-column label="操作" width="150" align="center">
        <template #default="scope">
          <el-button @click="handleAdd(scope.row)" type="primary" size="small" plain>修改</el-button>
          <el-button @click="del(scope.row)" type="danger" size="small" plain>删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small" type="primary">确定</el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog :close-on-click-modal="false" v-model="dialogFormVisibleAdd" title="股权变更信息" width="1000px"
    destroy-on-close>
    <el-row>
      <el-col :span="5">
        股东
      </el-col>
      <el-col :span="7">
        <el-input v-model="form.shareholder" clearable size="small" class="w-220" />
      </el-col>
      <el-col :span="5">
        股权变更日期
      </el-col>
      <el-col :span="7">
        <el-date-picker v-model="form.changeDate" type="date" clearable size="small" value-format="YYYY-MM-DD" />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">
        股权变更前比例(小数)
      </el-col>
      <el-col :span="7">
        <el-input v-model="form.frontProportion" clearable size="small" class="w-220" />
      </el-col>
      <el-col :span="5">
        股权变更后比例(小数)
      </el-col>
      <el-col :span="7">
        <el-input v-model="form.afterProportion" clearable size="small" class="w-220" />
      </el-col>
    </el-row>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false" size="small">取消</el-button>
        <el-button type="primary" @click="save" size="small">
          保存
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import { getCompanyInfo, updateGsnb, deleteGsnb } from "@/api/gsnb";
import { ElMessage, ElMessageBox } from "element-plus";
const props = defineProps({
  startAccountPeriod: {
    default: "",
    type: String,
  },
  comId: {
    default: 0,
    type: Number,
  },
});
const dialogFormVisible = ref(false)
const dialogFormVisibleAdd = ref(false)
const tableData = ref([])
const form = ref({})
const loading = ref(false)
const getParams = () => {
  dialogFormVisible.value = true
  getList()
}
const getList = () => {
  loading.value = true
  getCompanyInfo({
    comId: props.comId,
    year: props.startAccountPeriod,
    tableName: "gsnb_equity_change",
  }).then(res => {
    loading.value = false
    if (res.data.msg == 'success') {
      tableData.value = res.data.data.data || []
    }
  })
}
const add = () => {
  form.value = {}
  dialogFormVisibleAdd.value = true
}
const handleAdd = (row) => {
  form.value = Object.assign({}, row)
  dialogFormVisibleAdd.value = true
}
function convertToNumber(value) {
  if (!value) {
    return 0;
  }
  const num = Number(value);
  return isNaN(num) ? 0 : num;
}
const save = () => {
  form.value.frontProportion = convertToNumber(form.value.frontProportion)
  form.value.afterProportion = convertToNumber(form.value.afterProportion)
  form.value.comId = props.comId;
  form.value.year = props.startAccountPeriod;
  let param = {
    tableName: "gsnb_equity_change",
    query: form.value,
  };
  updateGsnb(param).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success('保存成功')
      dialogFormVisibleAdd.value = false;
      getList();
    }
  });
}
const del = (row) => {
  ElMessageBox.confirm("确认删除该条信息吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    deleteGsnb({ ids: [row.id], tableName: "gsnb_equity_change" }).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success('删除成功')
        getList();
      }
    });
  });
}
defineExpose({ getParams })
</script>

<style lang="scss" scoped>
.top_select {
  margin-bottom: 10px;
  text-align: right;
}

.el-row {
  display: flex;
  border: 1px solid #b9b9b9;
  border-bottom: none;
  border-right: none;
}

.el-row:first-child {
  border-top: 1px solid #b9b9b9;
}

.el-row:last-child {
  border-bottom: 1px solid #b9b9b9;
}

.el-col-5 {
  text-align: right;
  background-color: #ecf7f9;
  padding-right: 10px;
  border-right: 1px solid #b9b9b9;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
}

.el-col-7 {
  padding-left: 10px;
  border-right: 1px solid #b9b9b9;
  height: 34px;
  line-height: 34px;
}
</style>
