<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="drawer"
    title="授权管理"
    width="1200px"
    append-to-body
    destroy-on-close
  >
    <div class="top">
      <div>
        <select-common
          placeholder="请选择登录方式"
          v-model:modelValue="listQuery.loginMethod"
          :options="options"
          class="w120 ml-0"
          @selectChange="getList"
        />
        <el-input
          size="small"
          placeholder="请输入用户名/手机号"
          v-model="listQuery.zzridno"
          clearable
          class="w120 mr-5"
          @keyup.enter="getList"
        ></el-input>
        <el-input
          size="small"
          placeholder="请输入密码"
          v-model="listQuery.zzrmn"
          clearable
          class="w120 mr-5"
          @keyup.enter="getList"
        ></el-input>
        <el-input
          size="small"
          placeholder="请输入代理机构号"
          clearable
          v-model="listQuery.idNo"
          @keyup.enter="getList"
          class="w120"
        ></el-input>
        <el-button size="small" type="primary" icon="Search" @click="getList"
          >搜索</el-button
        >
      </div>
      <div>
        <el-button type="primary" size="small" @click="add" icon="Plus"
          >新增</el-button
        >
        <el-button
          type="danger"
          size="small"
          @click="delAll"
          icon="Delete"
          plain
          >批量删除</el-button
        >
      </div>
    </div>
    <!-- <div class="hint">温馨提示：删除登录信息会解绑所有与此绑定信息的公司</div> -->
    <el-table
      :data="list"
      style="width: 100%"
      border
      :height="contentStyleObj"
      stripe
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column
        type="index"
        label="序号"
        width="55"
        align="center"
        fixed
      />
      <el-table-column prop="loginMethod" label="登录方式" min-width="120" />
      <el-table-column prop="zzridno" label="用户名/手机号" min-width="120" />
      <el-table-column prop="zzrmn" label="密码" min-width="120" />
      <el-table-column prop="idNo" label="代理机构号" min-width="120">
        <template #default="scope">
          {{ scope.row.loginMethod == "新版登录" ? "" : scope.row.idNo }}
        </template>
      </el-table-column>
      <el-table-column label="短信接收方式" min-width="120">
        <template #default="scope">
          {{ scope.row.yzmType == 1 ? "手动接收" : "自动接收" }}
        </template>
      </el-table-column>
      <el-table-column prop="count" label="绑定公司数" width="90">
      </el-table-column>
      <el-table-column label="操作" align="center" width="300">
        <template #default="scope">
          <el-button
            size="small"
            link
            icon="Connection"
            @click="checkCompany(scope.row)"
            :disabled="scope.row.count == 0"
            >查看绑定公司</el-button
          >
          <el-button size="small" icon="Edit" @click="edit(scope.row)" link :disabled="scope.row.keepStatus == 4"
            >编辑</el-button
          >
          <el-tooltip
            :disabled="scope.row.count == 0"
            content="此登录方式已绑定公司，请先取消绑定再操作删除"
            placement="top"
            effect="dark"
          >
            <el-button
              type="danger"
              size="small"
              icon="Delete"
              @click="del(scope.row)"
              :disabled="scope.row.count > 0"
              link
              >删除</el-button
            >
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="foot">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </el-dialog>
  <taxManForm ref="taxManFormRef" @success="getList" />
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    title="绑定公司"
    append-to-body
    destroy-on-close
  >
    <div class="text_box">
      <el-button
        type="danger"
        size="small"
        @click="cancelAll"
        icon="Unlock"
        plain
        >取消绑定</el-button
      >
    </div>
    <el-table
      :data="companyList"
      style="width: 100%"
      border
      :maxHeight="400"
      stripe
      @selection-change="handleSelectionComChange"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column
        type="index"
        label="序号"
        width="55"
        align="center"
        fixed
      />
      <el-table-column prop="name" label="公司名称" min-width="280" />
      <el-table-column label="操作" align="center" width="130">
        <template #default="scope">
          <el-button
            type="danger"
            plain
            size="small"
            icon="Unlock"
            @click="cancelOne(scope.row)"
            >取消绑定</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import {
  companyLoginUserList,
  deleteCompanyLoginUser,
  companyLoginUserToCom,
  cancelCompanyLoginCom,
} from "@/api/company";
import taxManForm from "@/components/authorizedLogin/taxManForm.vue";
export default {
  name: "impowerManage",
  components: {
    taxManForm,
  },
  props: {
    from: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      list: [],
      drawer: false,
      loading: false,
      dialogFormVisible: false,
      contentStyleObj: {},
      comInfo: {},
      options: [
        { label: "全部", value: "" },
        { label: "新版登录", value: "新版登录" },
        { label: "代理登录", value: "代理登录" },
        { label: "政务网登录", value: "政务网登录" },
      ],
      listQuery: {
        page: 1,
        limit: 20,
      },
      sels: [],
      companyList: [],
      dialogVisible: false,
      loginUserId: 0,
      cancelSels: [],
    };
  },

  mounted() {
    this.contentStyleObj = this.$getHeight(300);
  },
  methods: {
    init() {
      this.drawer = true;
      this.getList();
    },
    getList() {
      this.loading = true;
      companyLoginUserList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    handleSelectionChange(e) {
      this.sels = e;
    },
    add() {
      this.$refs.taxManFormRef.init();
    },
    edit(row) {
      this.$refs.taxManFormRef.init(row);
    },
    del(row) {
      this.$confirm("确认删除此办税人信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteCompanyLoginUser({
          ids: [row.id],
        }).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("删除成功");
            this.getList();
          }
        });
      });
    },
    //批量删除
    delAll() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择要删除的信息", 1);
        return;
      }
      let fail = this.sels.find((v) => v.count > 0);
      if (fail) {
        this.$qzfMessage("存在已绑定公司，请先解绑", 1);
        return;
      }
      this.$confirm(`确认删除${this.sels.length}条办税人信息吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.sels.map((v) => {
          return v.id;
        });
        deleteCompanyLoginUser({ ids }).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("删除成功");
            this.getList();
          }
        });
      });
    },
    //查看绑定的公司
    checkCompany(row) {
      this.loginUserId = row.id;
      this.getCompanyList();
    },
    getCompanyList() {
      companyLoginUserToCom({ loginUserId: this.loginUserId }).then((res) => {
        if (res.data.msg == "success") {
          this.companyList = res.data.data.list ? res.data.data.list : [];
          this.dialogVisible = true;
        }
      });
    },
    handleSelectionComChange(val) {
      this.cancelSels = val;
    },
    //取消绑定
    cancelOne(row) {
      this.$confirm("确认取消绑定吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cancelCompanyLoginCom({ ids: [row.loginId] }).then((res) => {
          if (res.data.msg == "success") {
            if (res.data.data.successCount == 1) {
              this.$qzfMessage("解绑成功");
            } else {
              this.$qzfMessage("该公司只绑定一个登录方式，不可取消绑定", 1);
            }
            this.getCompanyList();
            this.getList();
          }
        });
      });
    },
    cancelAll() {
      if (this.cancelSels.length == 0) {
        this.$qzfMessage("请选择要解绑的公司", 1);
        return;
      }
      this.$confirm("确认批量取消绑定吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cancelCompanyLoginCom({
          ids: this.cancelSels.map((v) => v.loginId),
        }).then((res) => {
          if (res.data.msg == "success") {
            if (res.data.data.successCount == this.cancelSels.length) {
              this.$qzfMessage("解绑成功");
            } else {
              this.$qzfMessage(`解绑成功${res.data.data.successCount}个`);
            }
            this.getCompanyList();
            this.getList();
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.w120 {
  width: 120px;
}
.hint {
  color: var(--themeColor, #17a2b8);
  font-size: 14px;
  margin: 10px 0;
}
.foot {
  margin: 10px 0;
  & > div {
    float: right;
  }
}
.text_box {
  text-align: right;
  margin-bottom: 10px;
}
</style>
