<template>
  <div class="app-container" v-loading="divLoading">
    <el-button icon="RefreshRight" size="small" style="margin-right: 5px;margin-bottom: 10px;"  @click="init()"></el-button>
    <el-input
      v-model="listQuery.name"
      placeholder="请输入公司名/手机号/编号"
      size="small"
      style="width: 180px;margin-bottom: 10px;"
      @keyup.enter="init()"
      clearable
    ></el-input>
    <el-button
      size="small"
      type="primary"
      @click="init()"
      style="margin-bottom: 10px;"

      >搜索</el-button
    >
    <el-select size="small" v-model="listQuery.status" placeholder="请选择授权状态" @change="init"  style="margin-bottom: 10px;width:85px">
      <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
    <el-select size="small" v-model="listQuery.automatic" placeholder="迁移工厂状态" @change="init"  style="margin-bottom: 10px;width:110px" v-if="this.orgIsAuto == 1">
      <el-option v-for="item in automaticOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
      <el-button style="float: right;margin-left: 5px;" size="small" type="primary" plain icon="CreditCard" @click="updateBsr">
      新增绑定登录信息</el-button>
      <el-button style="float: right;margin-left: 0;" size="small" type="primary" plain icon="Connection" @click="showImpower">
      授权管理</el-button>
    <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="margin-bottom: 10px;width:170px;float: right;" @change="init"></selectuser>
    <selectcity v-model:citys="listQuery.districtCode" style="width:110px;float: right;margin: 0;" @success="init"></selectcity>

    <el-select size="small" v-model="listQuery.loginMethod" placeholder="请选择登录方式" style="margin-bottom: 10px;width:110px;float: right;" @change="init">
      <el-option v-for="item in types" :key="item.value" :label="item.name" :value="item.value"></el-option>
    </el-select>
    <n-data-table
      ref="tableDate"
      :columns="getTableColumns()"
      :data="tableData"
      :max-height="contentStyleObj"
      :row-key="row => row.id"
      :indent="14"
      :loading="listLoading"
      :scroll-x="1320"
      :on-update:checked-row-keys="selectChangeEvent"
      :row-props="() => ({style: { height: '40px' }})"
      :single-line="false"
      :checked-row-keys="ids"
      virtual-scroll
      bordered
      striped
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
    </n-data-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="init"
        type="loginTaxBureau"
      />
    </div>

    <el-dialog title="短信验证码验证" v-model="dialogFormVisible" width="20%" destroy-on-close  :close-on-click-modal="false">
      <el-form  :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="手机号" :label-width="formLabelWidth" prop="mobileRec">
          <el-input v-model="form.mobileRec" autocomplete="off" size="small" style="width: 100%;" placeholder="请输入手机号" disabled></el-input>
        </el-form-item> 
        <el-form-item label="验证码" :label-width="formLabelWidth" prop="code">
          <el-input v-model="form.code" autocomplete="off"  size="small" style="width: 100%" placeholder="所收到的税局短信息验证码"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="makeSureSend" size="small" :loading="btnLoading"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 切换短信接收方式 -->
    <el-dialog title="切换短信接收方式" v-model="dialogFormVisibleYzmType" width="330px" destroy-on-close  :close-on-click-modal="false">
      <div style="font-size: 13px;color:var(--themeColor,#17a2b8);margin-bottom:20px">
        <div>
          <i style="font-size: 13px;" class="iconfont icon-gantanhao1" ></i> <span >温馨提示</span>
        </div>
        <div>
         1、自动接收需要借助 “短信转发设备” 
        </div>
        <div>
         2、手动接收，需要在规定时间内输入验证码
        </div>
      </div>
      <span style="font-size:13px">切换为：</span>
      <el-select v-model="yzmType" placeholder="请选择短信接收方式" style="width:210px" size="small">
        <el-option label="自动接收" :value="0"></el-option>
        <el-option label="手动接收" :value="1"></el-option>
      </el-select>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisibleYzmType = false" size="small">取 消</el-button>
          <el-button type="primary" @click="makeSureChangeYzmType" size="small"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 批量设置手机号 -->
    <el-dialog title="设置办税手机号" v-model="dialogFormVisibleMobileType" width="330px" destroy-on-close  :close-on-click-modal="false">
      <div style="font-size: 13px;color:var(--themeColor,#17a2b8);margin-bottom:20px">
        <div>
          <i style="font-size: 13px;" class="iconfont icon-gantanhao1" ></i> <span >温馨提示</span>
        </div>
        <div>
         1、自动接收需要借助 “短信转发设备” 
        </div>
        <div>
         2、手动接收，需要在规定时间内输入验证码
        </div>
        <div>
         3、同一手机号，不能跨税局使用！！
        </div>
      </div>
      <!-- <span style="font-size:13px">
        设置手机号为：
      </span> -->
      <el-form :model="ruleFormMobile" :rules="rulesMobile" ref="ruleFormMobile" label-width="115px" class="demo-ruleForm">
        <el-form-item label="设置手机号为：" prop="allMobileRec" style="font-size:13px">
          <el-input v-model.trim="ruleFormMobile.allMobileRec" style="width:160px" size="small"></el-input>
        </el-form-item>
      </el-form>
      <!-- <el-select v-model="yzmType" placeholder="请选择短信接收方式" style="width:210px">
        <el-option label="自动接收" :value="0"></el-option>
        <el-option label="手动接收" :value="1"></el-option>
      </el-select> -->
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisibleMobileType = false" size="small">取 消</el-button>
          <el-button type="primary" @click="makeSureChangeAllMobile" size="small"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 修改手机号 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="dialogVisibleEdit"
      title="修改办税人信息"
      width="400px"
    >
    
    </el-dialog>
    <changeLoginMethod ref="changeLoginMethod" @success="init"></changeLoginMethod>
    <!-- 税局小助手未安装提示 -->
    <loginTaxAlert ref="loginTaxAlert"></loginTaxAlert>
    <impowerManage ref="impowerManage" />
    <updateInfo ref="updateInfo" @success="init"/>
    <authorizedLogin ref="authorizedLogin" :operaType="operaType"/>
  </div>
  <!-- 图片预览 -->
  <el-image-viewer
    v-if="showImagePreview"
    :zoom-rate="1.2"
    @close="showImagePreview = false"
    :url-list="[codeImg]"
  />
</template>
<script lang="jsx">
import {
  loginTaxInfo,
  taxBureComList,
  saveEaCompanyYzmType,
  saveEaCompanyMobileRec,
  getQrcode,
  autoEmpower,
  handEmpower,
  companyUserList
} from "@/api/company";
import axios from "axios";
import { sendTask } from "@/api/collection"
import changeLoginMethod  from "./components/changeLoginMethod.vue"
import selectcity from "@/components/Screening/selectcity";

import impowerManage from './components/impowerManage.vue'
import updateInfo from "./components/updateInfo.vue";
import DynamicPopover from '@/components/DynamicPopover/DynamicPopover'
import TableSortHeader from '@/components/table-cell-render/table-sort-header/TableSortHeader'
import TableSortCell from '@/components/table-cell-render/table-sort-cell/TableSortCell'
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import KjTable from '@/components/table-cell-render/KjTable'
import authorizedLogin from '@/components/authorizedLogin'
import taxBureau from "@/components/loginTaxAlert/taxBureau.vue"
export default {
  name: "loginTaxBureau",
  components:{changeLoginMethod,selectcity,impowerManage,updateInfo,authorizedLogin},

  data() {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        return callback();
      }
    };
    return {
      orgId: this.$store.getters["user/user"].orgId,
      list: [],
      contentStyleObj: {}, //高度变化
      listLoading: false,
      divLoading:false,
      dialogVisible: false,
      dialogVisibleEdit: false,
      options: [],
      listQuery: {
        name: "",
        limit: 20,
        page: 1,
        loginMethod:1,
        userId:null,
        status:9,
        orgId:0,
      },
      userName:this.$store.getters['user/user'].cnName,
      total: 0,
      dialogFormVisible:false,
      form:{
        mobileRec:'',
        comId:0,
        code:""
      },
      rules: {
        mobileRec: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            min: 11,
            max: 11,
            message: '长度为11位',
            trigger: 'blur',
          },
        ],
        code:[
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ]
      },
      ruleFormMobile:{

      },
      rulesMobile:{
        allMobileRec:[
        { required: true, validator: validateMobile, trigger: "blur" },
        ]
        
      },
      types:[
        {
          name:'新版登录',
          value:1
        },
        {
          name:'代理登录',
          value:3
        },
        {
          name:'其他登录',
          value:0
        },
        {
          name:'全部',
          value:2
        }
      ],
      statusOptions:[
        {
          label:'全部',
          value:9
        },
        {
          label:'未授权',
          value:0
        },
        {
          label:'已授权',
          value:1
        },
        {
          label:'授权失败',
          value:2
        },
        {
          label:'已掉线',
          value:3
        },
        {
          label:'授权中',
          value:4
        },
        {
          label:'无需授权',
          value:5
        }
      ],
      automaticOptions:[
       {
          label:'全部',
          value:0
        },
        {
          label:'已迁移至财务工厂',
          value:1
        },
        {
          label:'未迁移至财务工厂',
          value:2
        },
  
      ],
      yzmType:undefined,
      dialogFormVisibleYzmType:false,
      yzmTypeComId:0,
      pageStatus: true,
      ids:[],
      dialogFormVisibleMobileType:false,
      allMobileRec:'',
      dialogTableVisible:false,
      failList:[],
      codeImg:"",
      codeLink:"",
      showImagePreview:false,
      visible:false,
      buttonLoading:false,
      currentImgViewName:"",
      gridData:[],//负责人数据
      totalPage:0,
      pageSize:50,
      tableData:[],
      currentPage:1,
      allChecked:false,
      btnLoading:false,
      operaType:"",
      orgIsAuto: this.$store.getters["user/user"].autoStatus,
    };
  },
  created() {
    this.listQuery.limit = localStorage.getItem('loginTaxBureau') ? localStorage.getItem('loginTaxBureau')*1 : 20
    this.contentStyleObj = this.$getHeight(260);
    this.init();
    this.initBus()
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  mounted() {
    this.$store.dispatch('tagsView/delCachedViewByName', "bookkeeping")
  },
  methods: {
    getTableColumns() {
      return [
        {
          type: 'selection',
          width: 50,
          fixed: 'left',
        },
        {
          key: 'sort',
          width: 70,
          align: 'center',
          fixed: 'left',
          resizable: true,
          title: () => {
            return <TableSortHeader onSortChange={data => this.sortChange(data)} />
          },
          render: (row) => {
            return <TableSortCell row={row} />
          }
        },
        {
          key: 'name',
          title: '公司名称',
          minWidth: 260,
          fixed: 'left',
          align: 'left',
          resizable: true,
          render: (row) => {
            return <TagNameCopy row={row}  status={row.status} showAuthIcon={false} v-slots={{
              default: () => <span onClick={() => this.checkImpower(row,'edit')} style="cursor:pointer;color:var(--themeColor,#17a2b8);font-weight:500;margin-left:10px;margin-top:1px;font-size:13px">
                <i class="iconfont icon-bianji23" style="font-size:15px;font-weight:590"></i>
                <span style="position:relative;display:inline-block;top:-1px">编辑</span>
              </span>
            }} />
          }
        },
        {
          key: 'manager',
          title: '法人',
          width: 60,
          resizable: true,
          ellipsis:true,
        },
        {
          key: 'district',
          title: '税局',
          dataKey: 'district',
          width: 85,
          resizable: true,
          render: (row) => {
            return <span>{ this.$cityFilter(row.district) }</span>
          }
        },
        {
          key: 'mobileRec',
          title: '手机号/账号',
          width: 170,
          resizable: true,
          align: 'center',
          render: (row) => {
            return <span>
              <masked-phone row={row}  showPhone={this.$buttonStatus('sqdl_sjhxs')} />
            </span>
          }
        },
        {
          key: 'keepStatus',
          title: '在线状态',
          dataKey: 'keepStatus',
          width: 155,
          resizable: true,
          render: (row) => {
            if (['330000', '440000'].includes(row.districtCode) || ['12', '42'].includes(row?.districtCode?.substring(0, 2)) || row.loginMethod != '新版登录') {
              return <div style="color:green">无需授权</div>
            } else if (!row.mobileRec && !row.zzridno) {
              <div style="display:block;text-align: left;"><i class="iconfont icon-gantanhao" style="margin-right: 4px;"/><span class="wsb" style="border: 0;" size="small">未授权</span></div>
            } else if (row.keepStatus == 1) {
              return <div style="color:green">已授权，税局在线</div>
            }
            const noAuth = () => <div>
              <i class="iconfont icon-gantanhao" style="margin-right: 4px;"/><span class="wsb" style="border: 0;" size="small">未授权</span>
            </div>
            const authFail = () => {
                return <>{ row.keepStatus == 2 && <span>
                     <i class={'iconfont icon-cuowu'} style="margin-right: 4px;" />
                     <span class="sbyc2">授权失败</span> 
                  </span>
                }</>
            }
            const offline = () => <>
              { 
              row.keepStatus == 3 && <span>
                  <i class="iconfont icon-cuowu" style="margin-right: 4px;"></i>
                  <span class="sbyc2">已掉线</span> 
                </span>
              }
            </>
            const authing = () => <>
              {
                row.keepStatus == 4 && <span>
                  <i class="iconfont icon-gantanhao" style="margin-right: 4px;color:#409eff"></i>
                  <span class="sbz">授权中</span> 
                </span>
              }
            </>
            
            const not40 = () => <el-tooltip class="item" effect="dark"  placement="top-start" v-slots={{
              default: () => <div style="cursor: pointer;">
                {/* 失败的时候 */}
                <authFail />
                {/* 掉线的时候 */}
                <offline />
                <i class="iconfont icon-wenhao" style="color: red;margin-left: 5px;"></i>
              </div>,
              content: () => <div v-html={row.keepErr}></div>
            }} />
            return <div class={'keep-status-box'}>
              {
                row.keepStatus == 0 ? <noAuth /> : <div>
                    {
                      row.keepErr && row.keepStatus != 4 && row.keepStatus != 0 ? <not40 /> : <span>
                        <authFail />
                        <offline />
                        {/* 授权中 */}
                        <authing />
                      </span>
                    }
                  </div>
              }
            </div> 
          }
        },
        {
          key: 'kuaiji',
          title: '会计',
          width: 60,
          align: 'center',
          resizable: true,
          render: (row) => {
            return <div>
              <DynamicPopover v-slots={{
                reference: () => <div onClick={() => this.poper(row.id)} style="cursor:pointer;">
                  <i class="iconfont icon-huiji"></i>
                </div>,
                default: () => <KjTable list={this.gridData} />
              }} />
            </div>
          }
        },
        {
          key: 'opera',
          title: '操作',
          minWidth: 330,
          render: (row) => {
            return <>
              <el-button
                onClick={() => this.checkImpower(row,'sq')}
                size="small"
                type="primary"
                icon="SwitchButton"
                plain
                disabled={!this.$buttonStatus('sddl_dl')}
              >授权</el-button>
              <taxBureau comId={row.id}  onSuccess={() => this.init()} />
              <el-button
                size="small"
                type="primary"
                plain
                onClick={() => this.checkImpower(row,'sqlj')}
              >获取授权链接</el-button>
            </>
          }
        },
      ]
    },
    selectChangeEvent(keys) {
      this.ids = keys
    },
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(!this.pageStatus){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if(res.keepStatus == 4) {
            status = true
          }
        })
        if(status == true) {
          this.init()
        }
      });
    },
    init() {
      if(this.$store.getters["commons/params"].loginMobile){
        this.listQuery.name = this.$store.getters["commons/params"].loginMobile
      }
      if(this.$store.getters["commons/params"].taxLoginMethod == 0 || this.$store.getters["commons/params"].taxLoginMethod == 1 || this.$store.getters["commons/params"].taxLoginMethod == 3){
        this.listQuery.loginMethod = this.$store.getters["commons/params"].taxLoginMethod
      }
      if(this.$store.getters["commons/params"].loginOrg){
        this.listQuery.orgId = this.$store.getters["commons/params"].loginOrg
      }
      this.$store.dispatch("commons/setParam", { loginMobile: null });
      this.$store.dispatch("commons/setParam", { taxLoginMethod: 2 });
      this.$store.dispatch("commons/setParam", { loginOrg: 0 });
      this.dialogConfirm = false
      this.listLoading = true;
      this.ids = []
      taxBureComList(this.listQuery).then((res) => {
        this.listLoading = false;
        this.tableData = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total;
      });
    },
    handleUpdate(row) {
      let param = {
        taskName: 'tax-tpass-login',
        comIds: [row.id*1],
        period: "202301"
      }
      sendTask(param).then(res => {
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起,请稍后输入验证码',3)
          this.init()
          if(row.yzmType == 1){
            this.form.mobileRec = row.mobileRec
            this.dialogFormVisible = true
            this.form.content = ""
          }
        }
      })
    },
    InTax(row) {
      // if(this.orgId == 3576){
      //   this.$qzfMessage('此功能暂时无法使用',1)
      //   return
      // }
      // this.fullscreenLoading = true
      row.taxLoading = true
      let that = this;
      loginTaxInfo({ id: row.id }).then((res) => {
        
        let param = res.data.data.info;
        if (param.isLock) {
          this.$confirm(
            "任务端机器人正在执行报税任务，如要强制进入税局，账号将会被顶掉，导致任务中断，您确认进入税局吗？",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          ).then(() => {
            //  this.$alert('正在登录税局，预计15秒', '进入税局提示', {
            //    confirmButtonText: '确定',
            //  });
            this.$qzfMessage("打开中，预计15秒...");

            axios
              .post("http://localhost:9111/loginTax/autoLoginTax", param)
              .then((res) => {
                row.taxLoading = false
                if (res.data.msg != "success") {
                  this.init()
                  that.$alert(res.data.msg, "提示", {
                    confirmButtonText: "确定",
                  });
                }
              })
              .catch(function() {
                row.taxLoading = false
                setTimeout(() => {
                  that.$refs.loginTaxAlert.dialogVisible = true
                }, 1000);
                // that.$alert("请先安装客户端", "提示", {
                //   confirmButtonText: "确定",
                // });
                return;
              });
          }).catch(()=>{
            row.taxLoading = false
          });
        } else {
          // this.$alert('正在登录税局，预计15秒', '进入税局提示', {
          //    confirmButtonText: '确定',
          //  });
          this.$qzfMessage("打开中，预计15秒...");

          axios
            .post("http://localhost:9111/loginTax/autoLoginTax", param)
            .then((res) => {
              row.taxLoading = false
              if (res.data.msg != "success") {
                this.init()
                that.$alert(res.data.msg, "提示", {
                  confirmButtonText: "确定",
                });
              }
            })
            .catch(function() {
              row.taxLoading = false
              setTimeout(() => {
                that.$refs.loginTaxAlert.dialogVisible = true
              }, 1000);
              // that.$alert("请先安装客户端", "提示", {
              //   confirmButtonText: "确定",
              // });
              return;
            });
        }
      });
    },
    switchType(row, type) {
      let status = "";
      status = type == 1? '手动' : '自动'
      this.$confirm(`确定切换成${status}授权吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          id:row.id,
          yzmType:type
        }
        saveEaCompanyYzmType(param).then(res=>{
          if(res.data.msg == 'success'){
            this.$qzfMessage('修改成功');
            this.init()
          }
        })
      });
    },
    // 发送验证码
    sendSms(row){
      this.form.mobileRec = row.mobileRec
      this.form.comId = row.id
      this.dialogFormVisible = true
      this.form.code = ""
    },
    makeSureSend(){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          this.divLoading = true;
          let param = {
            tel:this.form.mobileRec,
            comId:this.form.comId,
            code:this.form.code,
          }
          handEmpower(param).then(res => {
            this.divLoading = false;
            this.btnLoading = false
            if(res.data.msg == "success"){
              this.dialogFormVisible = false
              this.$qzfMessage('授权成功');
              this.init()
            }else{
              this.dialogFormVisible = false
              this.init()
            }
          })
        } else {
          return false
        }
      })
    },
    // 老的
    // makeSureSend2(){
    //   this.$refs['ruleForm'].validate((valid) => {
    //     if (valid) {
    //       let params = {
    //         mobile_receive: this.form.mobileRec,
    //         mobile_send: this.userName,
    //         receive_time: new Date(),
    //         content: "验证码" + this.form.content,
    //       };
    //       axios.post(
    //         "https://public.listensoft.net/api/uploadTaxSms",
    //         params
    //       )
    //       .then((resss) => {
    //         if (resss.data.msg == "success") {
    //           this.init();
    //           this.$qzfMessage('发送成功');
    //           this.dialogFormVisible = false
    //         }
    //       }).catch((err) => {});
    //     } else {
    //       return false
    //     }
    //   })
    // },
    //切换短信接收方式
    changeYzmType(row){
      this.yzmType = row.yzmType
      this.yzmTypeComId = row.id
      this.dialogFormVisibleYzmType = true
    },
    changeAllYzmType(){
      if(this.ids.length == 0){
        this.$qzfMessage('请选择公司',1);
        return
      }
      this.dialogFormVisibleYzmType = true
    },
    makeSureChangeYzmType(){
      let param = {
        ids:[this.yzmTypeComId],
        yzmType:this.yzmType,
      }
      if(this.ids.length != 0){
        param.ids = this.ids
      }
      saveEaCompanyYzmType(param).then(res=>{
        if(res.data.msg == 'success'){
          this.dialogFormVisibleYzmType = false
          this.$qzfMessage('修改成功');
          this.init()
        }
      })
    },
    // 批量切换手机号
    changeAllMobile(){
      if(this.ids.length == 0){
        this.$qzfMessage('请选择公司',1);
        return
      }
      this.dialogFormVisibleMobileType = true
    },
    makeSureChangeAllMobile(){
      this.$refs['ruleFormMobile'].validate((valid) => {
        if (valid) {
          let param = {
            ids:this.ids,
            mobileRec:this.ruleFormMobile.allMobileRec
          }
          saveEaCompanyMobileRec(param).then(res=>{
            if(res.data.msg == 'success'){
              this.dialogFormVisibleMobileType = false
              this.$qzfMessage('修改成功');
              this.init()
            }
          })
        } else {
          return false
        }
      })
    },
    // 表格排序
    sortChange() {
      this.listQuery.desc = this.listQuery.desc === 1 ? 0 : 1
      this.init()
    },
    annualLoss(row){
      this.$refs.changeLoginMethod.getList(row)
    },
    showImpower(){
      this.$refs.impowerManage.init()
    },
    checkImpower(row,type){
      this.operaType = type
      this.$nextTick(()=>{
        this.$refs.authorizedLogin.init(row)
      })
    }, 
    updateBsr(){
      if(this.ids.length == 0){
        this.$qzfMessage('请选择公司',1);
        return
      }
      // this.dialogVisibleEdit = true
      this.$refs.updateInfo.init(this.ids)
    },
    getLink(row){
      getQrcode({comId:row.id}).then(res=>{
        this.codeImg = "data:image/png;base64," + res.data.data.qrcode.replace(/[\r\n]/g, "") ;
        this.codeLink = res.data.data.url
        this.visible = true
      })
    },
    openLink(row,type){
      if(type == 1){
        this.currentImgViewName = row.name
        let canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        let img = new Image()
        img.src = this.codeImg
        img.onload = () => {
          canvas.setAttribute('width',img.width)
          canvas.setAttribute('height',img.height + 52)
          //绘制图片
          context.drawImage(img,0,0,img.width, img.height)
          //绘制底部矩形
          context.fillStyle = 'rgba(29,33,41,0.6)'; //fillstyle设置填充颜色
          context.fillRect(0,img.height,img.width,52);
          //字体
          context.font = '12px Arial'
          context.fillStyle='#fff'; //fil1Style设置填充颜色
          context.textAlign = "center"
          context.textBaseline = "middle"
          //字体文字，显示位置 图片上需要n个文字就写n个context.fillText(文字,上下,左右);
          context.fillText(this.currentImgViewName, img.width / 2,img.height + 26,img.width-10)
          let type2 = this.codeImg.replace(/.+\./g,'')
          this.codeImg = canvas.toDataURL(`image/${type2}`,1.0)
        }
        this.showImagePreview = true
      }else{
        this.$copyComName(this.codeLink)
      }
    },

    // 新的授权 自动授权
    shouquan(row){
      row.loading = true
      this.init()
      this.zidongShouQuan(row)
    },
    // 自动授权
    zidongShouQuan(row){
      this.divLoading = true;
      autoEmpower({comId:row.id,yzmType:row.yzmType}).then(res => {
        row.loading = false
        this.divLoading = false;
        // 手动成功之后打开弹窗
        if(res.data.data == 2 && row.yzmType == 1){
          this.init()
          this.sendSms(row)
        }else if(res.data.msg == "success"){
          this.$qzfMessage('已授权');
          this.init()
        }else{
          this.init()
        }
      })
    },
    // 列表点击
    poper(val){
      companyUserList({comId:val}).then(res=>{
        this.gridData = res.data.data.list
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }
  

  p{
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
.iconfont {
  font-size: 13px;
}
li {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  span{
    margin-left: 5px;
  }
}
li:hover{
  color: #17a2b8;
}

</style>
<style lang="less">
.classPrompt{
    .el-message-box__container{
      color:red
    }
}
.login-tax-bureau-opera {
  li {
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    span{
      margin-left: 5px;
    }
  }
  li:hover{
    color: #17a2b8;
  }
}
</style>
<style lang="scss">
.keep-status-box {
  .iconfont {
    font-size: 13px;
  }
}
.login-tax-bureau-opera-get-auth-link {
  li {
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    span{
      margin-left: 5px;
    }
  }
  li:hover{
    color: #17a2b8;
  }
}
</style>