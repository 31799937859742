<template>
  <div>
    <el-dialog
      title="编辑税局登录方式"
      v-model="dialogVisible"
      width="500px"
      :before-close="handleClose"
    >
    <el-form :model="temp" label-position="right" label-width="160px">
      <el-form-item label="所属税务总局:" prop="districtCode" class="item" @click.prevent="(()=>{})">
          <selectcity style="width:230px !important; margin:0" v-model:citys="temp.districtCode" @success="discodeChange()"></selectcity>
        </el-form-item>
      <el-form-item label="登录方式:" prop="loginMethod" class="item" @click.prevent="(()=>{})">
        <el-select v-model="temp.loginMethod" placeholder="请选择登录方式" style="width:230px;margin:0" size="small" @change="changeLoginMethod" :disabled="!userPass.loginMethodDisabled">
          <template v-if="userPass.loginMethodOption">
            <el-option v-for="item in userPass.loginMethodOption" :key="item" :label="item" :value="item"></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item v-if="userPass.idNo" :label="userPass.idNoLabel"  class="item" @click.prevent="(()=>{})">
        <!-- <el-input v-model="temp.idNo" style="width:100%;" :placeholder="userPass.idNoText" size="small" /> -->
        <el-input v-model="temp.idNo" style="width:230px;" :placeholder="userPass.idNoText" size="small" />

      </el-form-item>
      <el-form-item v-if="userPass.loginMethod == '新版登录' && temp.districtCode != '330000' && temp.districtCode != '440000' && temp.districtCode != '120000' && temp.districtCode != '420000' " label="办税手机号:" prop="mobileRec" class="item" @click.prevent="(()=>{})">
        <el-input v-model.trim="temp.mobileRec" style="width:230px;" size="small" type="text" v-if="this.$buttonStatus('sqdl_sjhxs')"/>
        <span v-else>
          {{ temp.mobileRec.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2") }}
        </span>
      </el-form-item>
  
      <el-form-item v-if="userPass.password" :label="userPass.passwordLabel" prop="password" class="item" @click.prevent="(()=>{})">
        <el-input v-model="temp.password" style="width:230px;" :placeholder="userPass.passwordText" size="small" type="text" />
      </el-form-item>
      <el-form-item v-if="userPass.zzrxm" label="办税人/自然人姓名:" prop="zzrxm" class="item" @click.prevent="(()=>{})">
        <el-input v-model="temp.zzrxm" style="width:230px;" size="small" :placeholder="userPass.zzrxmText" type="text" />
      </el-form-item>
      <el-form-item v-if="userPass.zzridno" :label="userPass.zzridnoTextLabel" prop="zzridno" class="item" @click.prevent="(()=>{})">
        <el-input v-model="temp.zzridno" style="width:230px;" size="small" :placeholder="userPass.zzridnoText" type="text" />
      </el-form-item>
      <el-form-item v-if="userPass.zzrmn" :label="userPass.zzrmnLabel" prop="zzrmn" class="item" @click.prevent="(()=>{})">
        <el-input v-model="temp.zzrmn" style="width:230px;" size="small" :placeholder="userPass.zzrmnText" type="text" />
      </el-form-item>
      <el-form-item v-if="userPass.xzsf" label="选择身份:" prop="xzsf" class="item" @click.prevent="(()=>{})">
        <el-select v-model="temp.xzsf" placeholder="选择身份" style="width:230px;margin:0" size="small">
          <el-option label="法人" value="法人"></el-option>
          <el-option label="财务负责人" value="财务负责人"></el-option>
          <el-option label="办税人" value="办税人"></el-option>
          <el-option label="购票员" value="购票员"></el-option>
          <el-option label="其他人员" value="其他人员"></el-option>
          <el-option label="附办税人" value="附办税人"></el-option>
          <el-option label="普通管理员" value="普通管理员"></el-option>
          <el-option label="实名办税" value="实名办税"></el-option>
          <el-option label="领票人" value="领票人"></el-option>
          <el-option label="开票员" value="开票员"></el-option>
          <el-option label="办税员" value="办税员"></el-option>
          <el-option label="法定代表人" value="法定代表人"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="userPass.jbr" label="经办人:" prop="jbr" class="item" @click.prevent="(()=>{})">
        <input type="password" style="display: none"/>
        <el-input v-model="temp.jbr" style="width:230px;" size="small" type="text" />
      </el-form-item>
      <el-form-item v-if="userPass.jbrIdno" label="经办人身份证号:" prop="jbrIdno" class="item" @click.prevent="(()=>{})">
          <input type="password" style="display: none"/> 
        <el-input v-model="temp.jbrIdno" style="width:230px;" size="small" type="text" />
      </el-form-item>
    
      <el-form-item label="个税登录方式:" prop="personalLoginType" class="item" @click.prevent="(()=>{})"> 
        <el-select v-model="temp.personalLoginType" placeholder="请选择个税登录方式" style="width:230px;margin:0" @change="changePersonalType" size="small">
          <el-option v-for="item in personal_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="个税密码:" prop="personalTaxPwd" class="item" @click.prevent="(()=>{})">
        <el-input v-model="temp.personalTaxPwd" style="width:230px;" type="text" size="small" />
      </el-form-item>
      <el-form-item v-if="temp.personalLoginType == '实名登录'" label="个税实名账号:" prop="personalIdno" class="item" @click.prevent="(()=>{})">
        <el-input v-model="temp.personalIdno" style="width:230px;" type="text" size="small" />
      </el-form-item>

    </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="makeSure" size="small">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { disCodeUserPass } from "@/utils";
import { companyLoginList ,saveEaCompanyLoginMethod } from "@/api/company";
import selectcity from "@/components/Screening/selectcity";


export default {
  name: 'changeLoginMethod',
  components:{
    selectcity,
  },
  data() {
    return {
      dialogVisible:false,
      userPass: {
        loginMethod: "",
        personalIdno:false
      },
      personal_type:[
        {
          value:'实名登录',
          label:'实名登录'
        },
        {
          value:'申报密码登录',
          label:'申报密码登录'
        }
      ],
      temp: {
        id:0,
        name: "",
        districtCode: [],
        districtName:'',
        regDate: "",
        taxNo: "",
        type: "",
        accountSystem: "",
        loginMethod: "证件登录",
        personalLoginType: "",
        personalIdno: "",
        personalTaxPwd: "",
        startAccountPeriod:'',
        password: "",
        xzsf:'',
        idNo: "",
        idType: "居民身份证",
        jbr: '',
        jbrIdno: '',
        zzridno:'',
        zzrmn:'',
        zzrxm:'',
        companyType:'有限责任公司'
      },
      loginMethod:'',
      districtCode:'',
      id:0,
    };
  },

  mounted() {
    
  },

  methods: {
    getList(row){
      this.districtCode = row.districtCode
      this.loginMethod = row.loginMethod
      this.id = row.id
      // 获取地区
      this.userPass = disCodeUserPass(row.districtCode,row.loginMethod)
      this.temp.loginMethod = row.loginMethod
      if (row.personalLoginType == "实名登录") {
          this.userPass.personalIdno = true;
      }else  {
          this.userPass.personalIdno = false;
      }
      this.temp = Object.assign({},row)
      companyLoginList({ids:[row.id]}).then((res) => {
        if (res.data.msg == "success") {
          // this.temp = res.data.data[0]
          this.temp.idNo = res.data.data[res.data.data.length-1].idNo
          this.temp.loginMethod = res.data.data[res.data.data.length-1].loginMethod
          this.temp.idType = res.data.data[res.data.data.length-1].idType
          this.temp.password = res.data.data[res.data.data.length-1].password
          this.temp.zzrxm = res.data.data[res.data.data.length-1].zzrxm
          this.temp.zzridno = res.data.data[res.data.data.length-1].zzridno
          this.temp.zzrmn = res.data.data[res.data.data.length-1].zzrmn
          this.temp.xzsf = res.data.data[res.data.data.length-1].xzsf
        }
      });
      this.dialogVisible = true
    },
    // 选择个税登录方式时
    changePersonalType(){
      if (this.temp.personalLoginType == "实名登录") {
          this.userPass.personalIdno = true;
      }else  {
          this.userPass.personalIdno = false;
      }
    },
    changeLoginMethod(){
      this.userPass = disCodeUserPass(this.temp.districtCode,this.temp.loginMethod);
      if (this.temp.personalLoginType == "实名登录") {
          this.userPass.personalIdno = true;
      }else  {
          this.userPass.personalIdno = false;
      }
    },
    // 选择地区时
    discodeChange(){
      
      //地区
      if(!this.temp.districtCode){
        return
      }
      this.temp.loginMethod = ''
      this.userPass = disCodeUserPass(this.temp.districtCode,this.temp.loginMethod)
      this.temp.loginMethod = this.userPass.loginMethod
      if (this.temp.personalLoginType == "实名登录") {
          this.userPass.personalIdno = true;
      }else  {
          this.userPass.personalIdno = false;
      }
    },

    //确认更改的时候
    makeSure(){
      this.temp.id = this.id
      if(this.temp.mobileRec &&!/^1[3456789]\d{9}$/.test(this.temp.mobileRec)){
        this.$qzfMessage('请输入正确的手机号',1);
        return
      }
      saveEaCompanyLoginMethod(this.temp).then(res => {
        if(res.data.msg == "success"){
          this.dialogVisible = false
          this.$qzfMessage('修改成功');
          this.$emit('success');
        }
      })
    }

  },
};
</script>

<style lang="scss" scoped>
</style>