<template>
  <!-- <div class="top">
    <el-icon style="color:#e6a23c;margin-right: 5px;"><WarningFilled /></el-icon
    >若2022年企业工商年报信息发生变动，请更新变动数据
  </div> -->
  <div class="content_data">
    <div style="display: flex; align-items: center;width:97%;margin: 0 auto;height:70px;padding-left: 3%;">
      <div class="each" @click="getType(1)">
        <div class="icon_bg">
          <i class="iconfont icon-gongsi1" style=" font-size: 18px;color:#17a2b8;"></i>
        </div>
        <div style="margin-left:3%;cursor: pointer;">
          <div style="margin-bottom:10px">企业</div>
          <div>
            <a>
              <span style="font-size: 16px;font-weight: 600;">{{ total }}</span>&nbsp;家
            </a>
          </div>
        </div>
      </div>
      <div class="each">
        <div>
          <el-progress type="circle" :percentage="cjData ? parseInt((cjData / total) * 100) : 0" color="#67c23a" />
        </div>
        <div style="margin-left:3%;cursor: pointer;">
          <div style="margin-bottom:10px" @click="getType(2)">采集完成数</div>
          <div>
            <a @click="getType(2)"> <span style="font-size: 16px;font-weight: 600;">{{ cjData }}</span></a>
            <span class="count" @click="getType(4)">未完成
              <a style="font-size: 14px;font-weight: 600;color: #333;">{{ Number(total) - Number(cjData) }}</a></span>
          </div>
        </div>
      </div>
      <div class="each">
        <div>
          <el-progress type="circle" :percentage="sbData ? parseInt((sbData / total) * 100) : 0" color="#67c23a" />
        </div>
        <div style="margin-left:3%;cursor: pointer;">
          <div style="margin-bottom:10px" @click="getType(3)">申报完成数</div>
          <div>
            <a @click="getType(3)"><span style="font-size: 16px;font-weight: 600;">{{ sbData }}</span></a>
            <span class="count" @click="getType(5)">未完成
              <a style="font-size: 14px;font-weight: 600;color: #333;">{{ Number(total) - Number(sbData) }}</a></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main">
    <div class="select">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-date-picker v-model="listQuery.year" type="year" value-format="YYYY" placeholder="选择年份" @change="getList"
          size="small" style="width:70px;margin-right: 5px;" disabled />
        <el-input size="small" style="width:200px" v-model="listQuery.name" @keyup.enter="getList"
          placeholder="请输入企业名称/编号" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right:10px">
          <el-icon>
            <Search />
          </el-icon><span> 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:0px 0px 0px 6px;width:580px" label-width="105px" :inline="true">
            <el-form-item label="纳税人类型：">
              <selecttaxtype v-model:type="listQuery.type" style="width:140px;margin:0"></selecttaxtype>
            </el-form-item>
            <el-form-item label="会计准则：">
              <el-select v-model="listQuery.accountSystem" placeholder="请选择会计准则" size="small"
                style="width:140px;margin: 0;" clearable>
                <el-option v-for="item in kjOptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="税局：">
              <selectcity v-model:citys="listQuery.districtCode" style="width:140px;margin: 0;"></selectcity>
            </el-form-item>
            <el-form-item label="选择人员：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:140px;margin: 0;">
              </selectuser>
            </el-form-item>
            <el-form-item label="工商联络员：">
              <el-input v-model="listQuery.telGsnb" style="width:140px" size="small" placeholder="请输入工商联络员"></el-input>
            </el-form-item>
            <el-form-item label="短信接收方式：">
              <el-select filterable v-model="listQuery.setGsnb" placeholder="选择短信接收方式" size="small"
                style="width:140px;margin: 0;" clearable>
                <el-option label="全部" value=""></el-option>
                <el-option label="自动接收" value="1"></el-option>
                <el-option label="手动接收" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公司类型：">
              <el-select v-model="listQuery.companyType" size="small" placeholder="请选择公司类型" filterable clearable
                style="width:140px;margin: 0;">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="采集结果：">
              <selectYearBusinessCjStatus v-model:allStatus="listQuery.allStatus"></selectYearBusinessCjStatus>
            </el-form-item>
            <el-form-item label="申报结果：">
              <el-checkbox-group size="small" v-model="listQuery.allStatus2">
                <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value"
                  @change="changeValue">{{ item.label }}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </search>
        <!-- <span class="hint"><i class="iconfont icon-gantanhao1"></i
          >暂未开放此功能</span> -->
      </div>
      <div>

        <el-button size="small" type="primary" plain @click="settingMobile" icon="Iphone"
          :disabled="!$buttonStatus('gsnb_bj')">设置联络员手机号</el-button>
        <el-button size="small" type="primary" plain @click="changeAllYzmType" icon="Iphone"
          :disabled="!$buttonStatus('gsnb_dxjsfs')">设置短信接收方式</el-button>
        <el-button size="small" type="primary" @click="batchCollect" icon="Aim" :disabled="!$buttonStatus('gsnb_cj') || sendTaskButtonStatus"
          :loading="cjLoading">批量采集</el-button>
        <el-button size="small" type="success" @click="batchShenbao" icon="Operation"
          :disabled="!$buttonStatus('gsnb_sb') || sendTaskButtonStatus" :loading="sbLoading">批量申报</el-button>
        <el-dropdown size="small" style="margin-left:10px">
          <el-button jz="false" type="primary" size="small">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <span @click="settingIdNo" v-if="$buttonStatus('gsnb_bj')">设置身份证号</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="settingPwd" v-if="$buttonStatus('gsnb_bj')">设置密码</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <el-table stripe :data="list" :height="contentStyleObj" style="width: 100%" border @select="handleSelectionChange"
      v-loading="loading" ref="tableScroll" id="tableLazyLoad" @select-all="handleSelectionChangeAll"
      @sort-change="sortChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="50" />
      <el-table-column label="编号" align="center" width="80" fixed="left" prop="sort" sortable="custom">
        <template #default="scope">
          <TableSortCell :row="scope.row" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="公司名称" fixed min-width="230">
        <template #default="scope">
          <TagNameCopy :row="scope.row" :showAuthIcon="false">
            <template v-slot>
              <span @click="allotEdit(scope.row)"
                style="cursor:pointer;color:var(--themeColor,#17a2b8);font-weight:500;margin-left:10px;margin-top:1px;font-size:13px"
                v-if="$buttonStatus('gsnb_bj')">
                <i class="iconfont icon-bianji23" style="font-size:15px;font-weight:590"></i>
                <span style="position:relative;display:inline-block;top:-1px">编辑</span>
              </span>
            </template>
          </TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column prop="accountSystem" label="会计制度" width="228">
        <template #default="scope">
          {{ $accountSystemFilter(scope.row.accountSystem) }}
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="税局" width="65">
        <template #default="scope">
          <span>{{ $cityFilter(scope.row.district) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" width="100" label="联络员手机号">
        <template #default="scope">
          <div v-if="scope.row.telGsnb">
            {{ scope.row.telGsnb }}
          </div>
          <div v-else>
            -----
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="manager" label="短信接收方式" width="120">
        <template #default="scope">
          <div v-if="scope.row.telGsnb">
            <i style="color:var(--themeColor,#17a2b8)" class="iconfont icon-usb" v-if="scope.row.setGsnb == 1"></i>
            <i style="color:var(--themeColor,#17a2b8)" class="iconfont icon-shoudong" v-else></i>
            <span v-if="scope.row.setGsnb == 0" style="margin-left:3px">手动接收</span>
            <span v-if="scope.row.setGsnb == 1" style="margin-left:3px">自动接收</span>
            <el-tooltip content="点击切换短信接收方式" placement="top" effect="dark">
              <i class="iconfont icon-bianji" style="color:var(--themeColor,#17a2b8);cursor: pointer;margin-left:16px"
                @click="changeYzmType(scope.row)" v-if="$buttonStatus('gsnb_dxjsfs')"></i>
            </el-tooltip>
          </div>
          <div v-else>
            -----
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" width="75" label="验证码">
        <template #default="scope">
          <div v-if="scope.row.telGsnb">
            <el-tooltip
              content="验证码两个小时内有效"
              effect="dark"
              placement="top"
            >
              <el-button
                @click="sendSms(scope.row)"
                size="small"
                type="text"
                icon="ChatRound"
                plain
                style="padding: 0;"
                :disabled="scope.row.setGsnb == 1"
                >验证码
              </el-button>
            </el-tooltip>
          </div>
          <div v-else>
           -----
          </div>
        </template>
      </el-table-column> -->
      <el-table-column prop="idNoGsnb" label="身份证号" width="160">
        <template #default="scope">
          <span>{{ scope.row.idNoGsnb ? (scope.row.idNoGsnb).replace(/^(.{6})(?:\d+)(.{4})$/, '$1******$2') : ''
            }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column  prop="pwdGsnb" label="密码" min-width="100">
        <template #default="scope">
          <span>{{ scope.row.pwdGsnb }}</span>  
        </template>
      </el-table-column> -->
      <el-table-column align="left" min-width="205" label="采集状态">
        <template #default="scope">
          <div class="item_icon">

            <span v-if="scope.row.image1">
              <i class="iconfont icon-picture" style="color:red;font-size:13px;" v-if="scope.row.bussinessStatus == 3"
                @click="openCj(scope.row, scope.$index)"></i>
              <i class="iconfont icon-picture" style="color:#67c23a;font-size:13px;"
                v-if="scope.row.bussinessStatus == 2" @click="openCj(scope.row, scope.$index)"></i>
            </span>
            <i :class="$batchSendIconCj(
              scope.row.taskStatus,
              scope.row.bussinessStatus
            )
              " v-else></i>
            <el-tooltip :disabled="!scope.row.bussinessLog && !scope.row.errLog" effect="dark" placement="top-start"
              style="display:inline-block">
              <template #content>
                <div v-html="(scope.row.bussinessLog
                  ? scope.row.bussinessLog
                  : '') + (scope.row.errLog
                    ? scope.row.errLog
                    : '')
                  "></div>
              </template>
              <div style="display:inline-block;">
                <span>{{
                  $batchSendCj(scope.row.taskStatus, scope.row.bussinessStatus)
                }}</span>
                <i v-if="
                  (scope.row.bussinessLog &&
                    (scope.row.bussinessStatus == 3 ||
                      scope.row.taskStatus == 3)) || scope.row.errLog
                " class="iconfont icon-wenhao" style="color:red;margin:0 0 0 4px;"></i>
              </div>
            </el-tooltip>

            <el-button type="text" size="small" @click="collectOne(scope.row)"
              :disabled="!scope.row.telGsnb && scope.row.district != 'hebei' && !$buttonStatus('gsnb_cj')">采集</el-button>
            <el-button type="text" size="small" @click="baobiaotianbao(scope.row)" style="margin-left: 0;"
              :disabled="(scope.row.taskStatus != 3 && scope.row.taskStatus != 4) || !$buttonStatus('gsnb_bbtb')">报表填报</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="left" min-width="130" label="申报状态">
        <template #default="scope">
          <div class="item_icon">
            <p>
              <span v-if="scope.row.image2">
                <i class="iconfont icon-picture" style="color:red;font-size:13px;"
                  v-if="scope.row.bussinessStatus2 == 3" @click="openSb(scope.row, scope.$index)"></i>
                <i class="iconfont icon-picture" style="color:#67c23a;font-size:13px;"
                  v-if="scope.row.bussinessStatus2 == 2 || scope.row.bussinessStatus2 == 20"
                  @click="openSb(scope.row, scope.$index)"></i>
              </span>
              <i :class="$batchSendIconSb(
                scope.row.taskStatus2,
                scope.row.bussinessStatus2
              )
                " v-else></i>
              <el-tooltip class="item" effect="dark" :disabled="!scope.row.errLog2 && !scope.row.bussinessLog2"
                placement="top-start">
                <template #content>
                  <div v-html="(scope.row.bussinessLog2
                    ? scope.row.bussinessLog2
                    : '') + (scope.row.errLog2
                      ? scope.row.errLog2
                      : '')
                    "></div>
                </template>
                <span>{{
                  $batchSendBusinessSb(
                    scope.row.taskStatus2,
                    scope.row.bussinessStatus2
                  )
                }}<i class="iconfont icon-wenhao" style="color:red;margin-left:4px" v-if="
                    ((scope.row.errLog2 || scope.row.bussinessLog2) &&
                      scope.row.taskStatus2 == 3) || scope.row.errLog2
                  ">
                  </i></span>
              </el-tooltip>
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="address" label="操作" width="85">
        <template #default="scope">
          <el-button type="text" size="small" @click="shenbaoOne(scope.row)"
            :disabled="(scope.row.taskStatus != 3 && scope.row.taskStatus != 4) || !$buttonStatus('gsnb_sb')">申报</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total > 0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
        @pagination="getList" type="businessYear" />
    </div>
  </div>
  <checkGsnb ref="checkGsnb" :yearTime="listQuery.year"></checkGsnb>
  <el-dialog title="短信验证码验证" v-model="dialogFormVisible" width="20%" destroy-on-close :close-on-click-modal="false">
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-form-item label="手机号" :label-width="formLabelWidth" prop="mobileRec">
        <el-input v-model="form.mobileRec" size="small" style="width: 100%;" placeholder="请输入手机号" disabled></el-input>
      </el-form-item>
      <el-form-item label="验证码" :label-width="formLabelWidth" prop="code">
        <el-input v-model="form.code" size="small" style="width: 100%" placeholder="收到的税局短信息验证码"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="makeSureSend" size="small">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 切换短信接收方式 -->
  <el-dialog title="切换短信接收方式" v-model="dialogFormVisibleYzmType" width="330px" destroy-on-close
    :close-on-click-modal="false">
    <span style="font-size:13px">切换为：</span>
    <el-select v-model="yzmForm.setGsnb" placeholder="请选择短信接收方式" style="width:210px" size="small">
      <el-option label="自动接收" :value="1"></el-option>
      <el-option label="手动接收" :value="0"></el-option>
    </el-select>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisibleYzmType = false" size="small">取 消</el-button>
        <el-button type="primary" @click="makeSureChangeYzmType" size="small">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 批量修改信息 -->
  <el-dialog title="修改工商年报信息" v-model="dialogEditVisible" width="385px" destroy-on-close :close-on-click-modal="false">
    <el-form :model="editForm" label-width="100px" size="small">
      <el-form-item label="联络员姓名:">
        <el-input v-model="editForm.nameGsnb" style="width: 200px;" placeholder="请输入联络员姓名"></el-input>
      </el-form-item>
      <el-form-item label="联络员手机号:">
        <el-input v-model="editForm.telGsnb" style="width: 200px;" placeholder="请输入联络员手机号"></el-input>
      </el-form-item>
      <el-form-item label="身份证号:">
        <el-input v-model="editForm.idNoGsnb" style="width: 200px;" placeholder="请输入身份证号"></el-input>
      </el-form-item>
      <el-form-item label="密码:">
        <el-input v-model="editForm.pwdGsnb" style="width: 200px;" placeholder="请输入密码"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogEditVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="editSure" size="small" :loading="editLoading">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <sbImgUrl ref="sbImgUrl"></sbImgUrl>

</template>
<script>
import selecttaxtype from "@/components/Screening/selecttaxtype";
import { createGsnb, getGsnbList } from "@/api/gsnb";
import { saveGsnbTel, saveSetGsnb,saveGsnbSingle } from "@/api/company";
import checkGsnb from "./components/checkGsnb.vue";
import { sendTask } from "@/api/collection";
import selectYearBusinessCjStatus from "./components/select/selectYearBusinessCjStatus.vue";
import selectcity from "@/components/Screening/selectcity";
import { getLastYear } from '@/utils'
import sbImgUrl from "./components/sbImgUrl.vue";
import axios from "axios";
import { ACCOUNT_SYSTEM_OPTION, COMPANY_TYPE_OPTION } from '@/utils/commonData.js'
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";

export default {
  name: "business-year",
  components: { checkGsnb, selectYearBusinessCjStatus, selectcity, selecttaxtype, sbImgUrl, TagNameCopy, TableSortCell },
  props: {},
  data() {
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        year: getLastYear(),
        companyType: ''
      },
      comTotal: 0,
      list: [],
      total: 0,
      contentStyleObj: {},
      loading: false,
      sels: [],
      ids: [],
      dialogFormVisible: false,
      form: {
        mobileRec: "",
        code: "",
        type: "",
      },
      options: [
        {
          value: "task-0",
          label: '未申报',
        },
        {
          value: "task-99",
          label: '发起中',
        },
        {
          value: "task-1",
          label: '排队中',
        },
        {
          value: "task-2",
          label: '申报中',
        },
        {
          value: "business-2",
          label: '申报成功',
        },
        {
          value: "task-4",
          label: '申报失败',
        },
        {
          value: "business-20",
          label: '已申报过',
        },

      ],
      rules: {
        mobileRec: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度为11位",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      userName: this.$store.getters["user/user"].cnName,
      pageStatus: true,
      cjData: 0,
      sbData: 0,
      yzmForm: {},
      dialogFormVisibleYzmType: false,
      editForm: {},
      dialogEditVisible: false,
      cjLoading: false,
      sbLoading: false,
      totalPage: 0,
      pageSize: 35,
      tableData: [],
      currentPage: 1,
      allChecked: false,
      kjOptions: ACCOUNT_SYSTEM_OPTION,
      editLoading: false,
      taskCity: ['hebei', 'tianjin', 'shandong', 'shanxi', 'jiangsu', 'anhui','guangxi','jilin','jiangxi','beijing','gansu','sichuan','qingdao','hunan','guizhou','hubei','liaoning','heilongjiang','chongqing','henan','guangdong'],
      noVerify: ['hebei', 'tianjin','sichuan','jiangxi','jilin']
    };
  },
  computed:{
    sendTaskButtonStatus(){
      if(this.sels.length == 0) return false
      return this.sels.some(v => !this.noVerify.includes(v.district))
    }
  },
  mounted() {
    this.listQuery.limit = localStorage.getItem("businessYear")
      ? localStorage.getItem("businessYear") * 1
      : 20;
    this.contentStyleObj = this.$getHeight(280);
    this.getList();
    this.initBus();
  },
  activated() {
    this.pageStatus = true;
  },
  deactivated() {
    this.pageStatus = false;
  },
  methods: {
    // 重置
    cancel() {
      let originLimit = this.listQuery.limit
      this.listQuery = {
        page: 1,
        limit: originLimit,
        name: "",
        year: getLastYear(),
        type: null,
        accountSystem: '',
        districtCode: null,
        userId: null,
        telGsnb: '',
        setGsnb: null,
        allStatus: [],
        allStatus2: [],
        companyType: ''
      }
      this.getList();
    },
    initBus() {
      this.$bus.on("refreshTask", () => {
        if (!this.pageStatus) {
          return;
        }
        if (this.sels.length != 0) {
          return;
        }
        if (this.list.length == 0) {
          return;
        }
        //刷新业务
        let status = false;
        this.list.forEach((res) => {
          if ([1, 2, 99].includes(res.taskStatus) || [1, 2, 99].includes(res.taskStatus2)) {
            status = true;
          }
        });
        if (status == true) {
          getGsnbList(this.listQuery).then((res) => {
            if (res.data.msg == "success") {
              this.tableData = res.data.data.list ? res.data.data.list : []
              this.total = res.data.data.total
              this.cjData = res.data.data.cj
              this.sbData = res.data.data.sb
              this.setScroll()
            }
          });
        }
      });
    },
    getList() {
      this.loading = true;
      getGsnbList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.list ? res.data.data.list : []
          this.total = res.data.data.total
          this.cjData = res.data.data.cj
          this.sbData = res.data.data.sb
          this.setScroll()
        }
      });
    },
    setScroll() {
      //先重置状态和数组
      this.allChecked = false
      this.sels = []
      this.ids = []
      //计算滚动页数
      this.totalPage = this.tableData.length / this.pageSize
      if (this.tableData.length % this.pageSize == 0) {
        this.totalPage = this.tableData.length / this.pageSize
      } else {
        this.totalPage = parseInt(this.tableData.length / this.pageSize) + 1
      }
      this.list = this.tableData.slice(0, this.currentPage * this.pageSize)
      this.lazyLoading()
    },

    lazyLoading() {
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if (that.allChecked && that.sels.length == that.tableData.length) {
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            } else if (that.sels.length != 0) {
              that.sels.map(v => {
                that.list.map(item => {
                  if (item.id == v.id) {
                    that.$nextTick(() => {
                      that.$refs.tableScroll.toggleRowSelection(item, true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    getType(item) {
      if (item == 1) {
        this.listQuery.allStatus = [];
        this.listQuery.allStatus2 = []
      } else if (item == 2) {
        this.listQuery.allStatus = ["business-2"];
      } else if (item == 3) {
        this.listQuery.allStatus2 = ["business-2", "business-20"];
      } else if (item == 4) {
        this.listQuery.allStatus = ["task-0", "task-99", "task-1", "task-2", "business-3"];
      } else if (item == 5) {
        this.listQuery.allStatus2 = ["task-0", "task-99", "task-1", "task-2", "task-4"];
      }
      this.getList()
    },
    getIds() {
      let arr = [];
      this.sels.map((v) => {
        arr.push(v.id);
      });
      this.ids = arr;
    },
    handleSelectionChange(val) {
      if (this.allChecked && this.list.length != this.tableData.length) {
        let currentIndex = 0
        this.list.map((v, i2) => {
          val.map(e => {
            if (e.id == v.id) {
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex + 1, this.tableData.length)
        let newArr = [...val, ...arr]
        this.sels = newArr
      } else {
        this.sels = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e) {
      if (e.length != 0) {
        this.sels = this.tableData
        this.getIds()
        this.allChecked = true
      } else {
        this.sels = []
        this.ids = []
        this.allChecked = false
      }
    },
    //批量设置手机号
    settingMobile() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      this.$prompt("请输入手机号", "设置手机号", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: "",
        beforeClose: (action, instance, done) => {
          if (action == "confirm") {
            let value = instance.inputValue;
            if (value && !/^1[3456789]\d{9}$/.test(value)) {
              this.$qzfMessage("请输入正确的手机号", 1);
            } else {
              let param = {
                comIds: this.ids,
                telGsnb: value,
                idNoGsnb: " ",
                pwdGsnb: " "
              };
              saveGsnbSingle(param).then((res) => {
                done();
                if (res.data.msg == "success") {
                  this.$qzfMessage("设置成功");
                  this.getList();
                }
              });
            }
          } else {
            done();
          }
        },
      });
    },
    //批量设置身份证号
    settingIdNo() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      this.$prompt("请输入身份证号", "设置身份证号", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: "",
        beforeClose: (action, instance, done) => {
          if (action == "confirm") {
            let value = instance.inputValue;
            if (value && !/^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/.test(value)) {
              this.$qzfMessage("请输入正确的身份证号", 1);
            } else {
              let param = {
                comIds: this.ids,
                idNoGsnb: value,
                telGsnb: " ",
                pwdGsnb: " "
              };
              saveGsnbSingle(param).then((res) => {
                done();
                if (res.data.msg == "success") {
                  this.$qzfMessage("设置成功");
                  this.getList();
                }
              });
            }
          } else {
            done();
          }
        },
      });
    },
    //批量设置密码
    settingPwd() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      this.$prompt("请输入密码", "设置密码", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: "",
        beforeClose: (action, instance, done) => {
          if (action == "confirm") {
            let value = instance.inputValue;
            if (value == "") {
              this.$qzfMessage("请输入密码", 1);
            } else {
              let param = {
                comIds: this.ids,
                pwdGsnb: value,
                telGsnb: " ",
                idNoGsnb: " "
              };
              saveGsnbSingle(param).then((res) => {
                done();
                if (res.data.msg == "success") {
                  this.$qzfMessage("设置成功");
                  this.getList();
                }
              });
            }
          } else {
            done();
          }
        },
      });
    },
    // 查看报表数据
    baobiaotianbao(row) {
      this.$refs.checkGsnb.init(row);
    },
    // 取数
    fetchGsnb(row) {
      createGsnb({ comId: row.id, year: this.listQuery.year }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("取数成功");
          this.getList();
        }
      });
    },
    // 发送验证码
    sendSms(row, type) {
      this.form.mobileRec = row.telGsnb;
      this.form.comId = row.id;
      this.form.type = type;
      this.dialogFormVisible = true;
      this.form.code = "";
    },
    makeSureSend() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let params = {
            mobile_receive: this.form.mobileRec,
            mobile_send: this.userName,
            receive_time: new Date(),
            content:
              "【公示系统】国家企业信用信息公示系统登录验证码：" +
              this.form.code
          };
          axios
            .post("https://public.listensoft.net/api/uploadTaxSms", params)
            .then((resss) => {
              if (resss.data.msg == "success") {
                // this.$qzfMessage("发送成功");
                this.getList();
                this.dialogFormVisible = false;
                // if(this.form.type == "cj"){
                //   this.collectTwo({id:this.form.comId})
                // }else if(this.form.type == "sb"){
                //   this.shenbaoTwo({id:this.form.comId})
                // }
              }
            })
            .catch(() => { });
        } else {
          return false;
        }
      });
    },
    //批量采集
    batchCollect() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      // let str = "";
      // this.sels.map((v) => {
      //   if (!v.telGsnb && v.district != "hebei" && v.district != "jilin") {
      //     str = v.name + "未设置手机号";
      //     return;
      //   }
      // });
      // if (str) {
      //   this.$qzfMessage(str, 1);
      //   return;
      // }

      let str3 = "";
      this.sels.map((v) => {
        if (!this.taskCity.includes(v.district)) {
          str3 = v.name + "该地区暂不支持";
          return;
        }
      });
      if (str3) {
        this.$qzfMessage(str3, 1);
        return;
      }

      // let str2 = "";
      // if (this.sels.length > 1) {
      //   this.sels.map((v) => {
      //     if (v.setGsnb == 0 && v.district != 'hebei' && v.district != 'jilin') {
      //       str2 = "手动接收方式不允许批量发起"
      //       return;
      //     }
      //   });
      // } else {
      //   if (this.sels[0].setGsnb == 0 && this.sels[0].district != 'hebei' && this.sels[0].district != 'jilin' && this.sels[0].district != 'qingdao') {
      //     this.checkSmsTime(this.sels[0], "cj")
      //     return
      //   } else if (this.sels[0].district == 'qingdao' && this.sels[0].companyType != '个体工商户' && this.sels[0].companyType != '农村合作社') {
      //     this.checkSmsTime(this.sels[0], "cj")
      //     return
      //   }
      // }
      // if (str2) {
      //   this.$qzfMessage(str2, 1);
      //   return;
      // }
      this.cjLoading = true
      let param = {
        taskName: "gsnb-cj",
        comIds: this.ids,
        period: this.listQuery.year,
      };
      sendTask(param).then((res) => {
        this.cjLoading = false
        if (res.data.msg == "success") {
          this.$qzfMessage("任务已发起", 3);
          this.getList();
          this.$queueMessage(res.data.data.queue);
        }
      });
    },
    collectOne(row) {
      if (!this.taskCity.includes(row.district)) {
        this.$qzfMessage(row.name + "该地区暂不支持", 1);
        return;
      }
      if (row.setGsnb == 0 && !this.noVerify.includes(row.district) && row.district != 'qingdao') {
        this.checkSmsTime(row, "cj")
        return
      } else if (row.district == 'qingdao' && row.companyType != '个体工商户' && row.companyType != '农村合作社') {
        this.checkSmsTime(row, "cj")
        return
      } else {
        this.collectTwo(row)
      }
    },
    //单独采集
    collectTwo(row) {
      let param = {
        taskName: "gsnb-cj",
        comIds: [row.id],
        period: this.listQuery.year,
      };
      sendTask(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("任务已发起", 3);
          this.getList();
          this.$queueMessage(res.data.data.queue);
        }
      });
    },
    //批量申报
    batchShenbao() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      let str = "";
      this.sels.map((v) => {
        if (v.taskStatus != 3) {
          str = v.name + "未采集";
          return;
        }
      });
      if (str) {
        this.$qzfMessage(str, 1);
        return;
      }
      let str3 = "";
      this.sels.map((v) => {
        if (!this.taskCity.includes(v.district)) {
          str3 = v.name + "该地区暂不支持发起";
          return;
        }
      });
      if (str3) {
        this.$qzfMessage(str3, 1);
        return;
      }

      // let str2 = "";
      // if (this.sels.length > 1) {
      //   this.sels.map((v) => {
      //     if (v.setGsnb == 0 && v.district != 'hebei' && v.district != 'jilin') {
      //       str2 = "手动接收方式不允许批量发起"
      //       return;
      //     }
      //   });
      // } else {
      //   if (this.sels[0].setGsnb == 0 && this.sels[0].district != 'hebei' && this.sels[0].district != 'jilin' && this.sels[0].district != 'qingdao') {
      //     this.checkSmsTime(this.sels[0], "sb")
      //     return
      //   } else if (this.sels[0].district == 'qingdao' && this.sels[0].companyType != '个体工商户' && this.sels[0].companyType != '农村合作社') {
      //     this.checkSmsTime(this.sels[0], "sb")
      //     return
      //   }
      // }
      // if (str2) {
      //   this.$qzfMessage(str2, 1);
      //   return;
      // }
      this.sbLoading = true
      let param = {
        taskName: "gsnb-sb",
        comIds: this.ids,
        period: this.listQuery.year,
      };
      sendTask(param).then((res) => {
        this.sbLoading = false
        if (res.data.msg == "success") {
          this.$qzfMessage("发送成功", 3);
          this.getList();
          this.$queueMessage(res.data.data.queue);
        }
      });
    },
    //单独申报
    shenbaoOne(row) {
      if (!this.taskCity.includes(row.district)) {
        this.$qzfMessage(row.name + "该地区暂不支持", 1);
        return;
      }
      if (row.setGsnb == 0 &&  !this.noVerify.includes(row.district) && row.district != 'qingdao') {
        this.checkSmsTime(row, "sb")
        return
      } else if (row.district == 'qingdao' && row.companyType != '个体工商户' && row.companyType != '农村合作社') {
        this.checkSmsTime(row, "sb")
        return
      } else {
        this.shenbaoTwo(row)
      }
    },
    shenbaoTwo(row) {
      let param = {
        taskName: "gsnb-sb",
        comIds: [row.id],
        period: this.listQuery.year,
      };
      sendTask(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("发送成功", 3);
          this.getList();
          this.$queueMessage(res.data.data.queue);
        }
      });
    },
    // 发申报采集的同时需要调一个查看验证码是否有效的接口
    checkSmsTime(row, type) {
      let params = {
        mobile: row.telGsnb,
        taxNo: row.taxNo
      }
      axios
        .post("https://public.listensoft.net/api/isGetGsSms", params)
        .then((resss) => {
          if (!resss.data.isOk) {
            // 需要弹窗
            this.$qzfMessage("发送成功");
            this.sendSms(row, type)
            if (type == "cj") {
              this.collectTwo(row)
            } else if (type == "sb") {
              this.shenbaoTwo(row)
            }
          } else {
            if (type == "cj") {
              this.collectTwo(row)
            } else if (type == "sb") {
              this.shenbaoTwo(row)
            }
          }
        })
        .catch(() => { });
    },
    //单个切换
    changeYzmType(row) {
      this.yzmForm.setGsnb = row.setGsnb
      this.yzmForm.comIds = [row.id]
      this.dialogFormVisibleYzmType = true
    },
    //多个
    changeAllYzmType() {
      if (this.sels.length == 0) {
        this.$qzfMessage('请选择公司', 1);
        return
      }
      this.yzmForm.comIds = this.ids
      this.yzmForm.setGsnb = 0
      this.dialogFormVisibleYzmType = true
    },
    makeSureChangeYzmType() {
      saveSetGsnb(this.yzmForm).then(res => {
        if (res.data.msg == 'success') {
          this.dialogFormVisibleYzmType = false
          this.$qzfMessage('修改成功');
          this.getList()
        }
      })
    },

    //单独修改公司信息
    allotEdit(row) {
      this.editForm = Object.assign({}, row)
      this.dialogEditVisible = true
    },
    editSure() {
      if (this.editForm.telGsnb && !/^1[3456789]\d{9}$/.test(this.editForm.telGsnb)) {
        this.$qzfMessage("请输入正确的手机号", 1);
        return
      }
      if (this.editForm.idNoGsnb && !/^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/.test(this.editForm.idNoGsnb)) {
        this.$qzfMessage("请输入正确的身份证号", 1);
        return
      }
      this.editLoading = true
      let param = {
        comIds: [this.editForm.id],
        telGsnb: this.editForm.telGsnb,
        idNoGsnb: this.editForm.idNoGsnb,
        pwdGsnb: this.editForm.pwdGsnb,
        nameGsnb: this.editForm.nameGsnb,
        type: 'all'
      };
      saveGsnbTel(param).then((res) => {
        this.editLoading = false
        if (res.data.msg == "success") {
          this.$qzfMessage("设置成功");
          this.dialogEditVisible = false
          this.getList();
        }
      });
    },
    //采集图片
    openCj(row, index) {
      let imageAll = []
      this.list.map(res => {
        let url = {
          name: res.name,
          url: ""
        }
        url.url = res.image1 ? 'https://file.listensoft.net/' + res.image1 + "?" + Math.random(10) : ""
        imageAll.push(url)
      })
      this.$refs.sbImgUrl.dialog(imageAll, index)
    },
    //申报
    openSb(row, index) {
      let imageAll = []
      this.list.map(res => {
        let url = {
          name: res.name,
          url: ""
        }
        url.url = res.image2 ? 'https://file.listensoft.net/' + res.image2 + "?" + Math.random(10) : ""
        imageAll.push(url)
      })
      this.$refs.sbImgUrl.dialog(imageAll, index)
    },
    // 表格排序
    sortChange(data) {
      if (data.order == "descending") {
        this.listQuery.desc = 1
      } else {
        this.listQuery.desc = 0
      }
      this.getList()
    },
  },
};
</script>

<style scoped lang="scss">
.top {
  background-color: #fef6ed;
  font-size: 13px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.content_data {
  width: 98%;
  margin: 10px auto;
  background-color: #d8ecff;
}

.each {
  width: 15%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}

.each a {
  text-decoration-line: underline;
  text-decoration-color: #141720;
}

.icon_bg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 35px;
}

:deep(.el-progress-circle) {
  width: 40px !important;
  height: 40px !important;
}

:deep(.el-progress__text) {
  font-size: 13px !important;
  min-width: 40px !important;
}

.count {
  display: inline-block;
  margin-left: 10px;
  color: #999;
  cursor: pointer;
}

.count::before {
  content: "";
  background: #c4c3c3;
  display: inline-block;
  width: 1px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
}

.main {
  width: 98%;
  margin: 0 auto;
}

.select {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.item_icon {
  display: inline-block;
  cursor: pointer;

  i {
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }

  p {
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}

.hint {
  margin-left: 5px;
  font-size: 14px;
  color: red;

  .icon-gantanhao1 {
    font-size: 13px;
    margin-right: 3px;
    color: red;
  }
}
</style>
